.bg-light-color{
    background: #f3f2ef;
}
.modal-content{
    border-radius: 1.2rem!important;
}
.k-dialog{
    border-radius: 0.6rem!important;
}
.k-window{
    border-radius: 0.6rem!important;
}
.k-window-titlebar{
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.demo-wrapper {
    overflow: auto;
     
    padding-bottom: 27px;
}
.h32.k-combobox button.k-button {
    background: white !important;
    border: none !important;
}
.h32.k-combobox .k-searchbar {
    height: 32px !important;
}

.h32.k-combobox .k-searchbar .k-input-inner {
    margin-top: 0px !important;
}
.h32.k-combobox .k-searchbar .k-input-solid:focus {
    box-shadow: none !important
}
.h37.k-combobox .k-searchbar {
    height: 37px !important;
}
.demo-wrapper+div.row {
    height: 41px !important;
} 

.logoInvitedCompany{
    float: right;
    text-align: right;
}
 body{
    background-color: #F3F2EF !important;
 }.mt-6 {
    margin-top: 6rem !important;
}
.core-rail {
    box-sizing: border-box;
    float: left;
    margin-bottom: 24px;
    margin-right: -1px;
    width: 791.99808px;
}
.neptune-grid {
    width: 1128px;
    box-sizing: content-box;
    padding: 0 30px;
    display: block;
    margin: auto;
    position: relative;
}.neptune-grid .right-rail, .neptune-grid.three-column .core-rail {
    box-sizing: border-box;
    float: left;
    margin-bottom: 24px;
    margin-right: -1px;
    margin-left: 25px;
}.neptune-grid .right-rail {
    width: 312.00192px;
}.card-header.cp-back-Img.p-0.w-100{
    border-top-left-radius: 0.55rem;
    border-top-right-radius: 0.55rem;
}
button.close.modal-close-btn{
    outline: 0;
}
.mainHeading {
    font-weight: bold;
}
.bg-tr {
    background-color: transparent !important;
}
.row.footerfinisher {
    position: fixed;
    bottom: 7px;
    left: 19%;
    width: 80%;
    right: 2%;
}
.k-pager-wrap {
    border-color: transparent !important;
    color: black !important;
    background-color: #ffffff !important;
    border-radius: 10px;
}
.k-combobox .k-dropdown-wrap{
    border-radius: 23px;
    border: 1px solid #968f78;
}
button.k-button.k-state-active{
    background-color: #a1ccd2 !important; 
}
button.k-button {
    border-radius: 23px !important;
    background-color: #F3F2EF !important;
}
.k-combobox .k-select{
    border-radius: 25px;
    border: none !important;
    background-image:none !important;
    background-color: transparent;
}.k-combobox .k-select:hover {
    border-radius: 25px;
    border: none !important;
    background-image:none !important;
    background-color: transparent;
}
.standardColorbtn{
background-color: white;

}
.standardColorbtn:hover{
    background-color: white;
    
    }
    .standardColorbtn:focus {
        background-color: white;
        
        }
.k-combobox .k-select:focus {
    border-radius: 25px;
    border: none !important;
    background-image:none !important;
    background-color: transparent;
}

.k-animation-container-shown{
    border-radius: 22px;
    border:none;
}
.k-animation-container {
    border-radius: 22px;
    border:none;
 }

.k-combobox > :hover .k-select,
.k-combobox .k-state-hover .k-select {
    border-color: transparent;
    color: #656565;
    background-image:none !important;
    background-color: transparent;
}
.k-button {
    background-color: #F3F2EF !important;
}


/* Let's get this party started */
::-webkit-scrollbar {
    width: 12px;
}
 
/* Track */
::-webkit-scrollbar-track {
   
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #000; 
   
}
::-webkit-scrollbar-thumb:window-inactive {
	background: #000;
}
.blockRow {
    width: 103.5%;
    display: block;
    margin-left: 2px;
    position: relative;
    top: -30px;
}

.lineSpacing{
    margin-top: 0.5rem;
    margin-bottom: 0;
    //border-top: 1px solid rgba(0,0,0,.5);
    //border: 1px solid #ccc;
}

.demo-wrapper {    
    width: 100%;
    // height: 950px;
    height: 100%;
    .articles-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
       
        overflow-y: auto;
        /* width       : 770px; */
        .articles-container {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 16px;
            /*height           : 340px; */
            cursor: default;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
}
.mainHeading,
.mainHeading i{
    font-size: 18px;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
 
.scroll-y {
    overflow-y: auto !important; 
   
    width: 100% !important;
}.demo-wrapper{
    height: 450px !important;
}
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
 
    .scroll-y {
        overflow-y: auto !important; 
        padding-bottom: 14rem !important;
        width: 100% !important;
    }.demo-wrapper{
        height: 450px !important;
    }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 
    .scroll-y {
        overflow-y: auto !important; 
        height: 100% !important;
        width: 100% !important;
        padding-bottom: 14rem !important;
    }.demo-wrapper{
        height: 450px !important;
    }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
    .scroll-y {
        overflow-y: auto !important;
        height: 440px !important;
        width: 100% !important;
        padding-bottom: 17rem !important;
     }
.demo-wrapper{
    height: 450px !important;
}
     
}
@media (min-width: 1800px) {  
    
    .scroll-y {
        overflow-y: auto !important;
        height: 100% !important;
        width: 100% !important;
        padding-bottom: 17rem !important;
     }
.demo-wrapper{
    height: 100% !important;
}
     
}
 .kendoInsideBtn{
    background: #f3f2ef !important;
    box-shadow: none !important;
    border: none;

 }.projectFinishcard {    
    background-color: #F3F2EF;
   border-radius: 22px;
    transition: 0.3s;
    border: 1px solid #968f78;
  
}.bottom-border{
    border-bottom:1px solid #F3F2EF !important ;
}.projectFinishCode {
    color: black;
    font-weight: 500 !important;
    float: left;
    margin-left: 18px;
}
.autoAdjustImage{
    max-width: 80%;
    max-height: 70%;
    display: block; 
    margin-left: auto;
    margin-right: auto;
} 
.box-large-img{
    height: 150px;
} .modal-backdrop.show{
    z-index: 99999  !important;
}
.modal{
    z-index: 999999 !important;

}  @media (min-width: 768px) {
    .modal-xl {
      width: 90%;
     max-width:1200px;
    }
  }

  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
  }

//   .ProseMirror p {
//     line-height: 0.2;
// }

.noteP
{
    p{
        margin: 0px !important;
        line-height: 18px !important; 
    }
}


.btnseperate
{
    background: #f3f2ef !important;
    box-shadow: none !important;
    border: 1px   solid rgb(0 0 0 / 60%) !important;
    text-align: left;
    color: black;

}


button.selectedSpecCls {
    position: absolute ;
    top: -32px;
    text-align: left;
    float: left;
    left: 0%;
}

button.selectedFinishCls {
    position: absolute ;
    top: -80px;
    text-align: left;
    float: left;
    left: 0%;
}

button.selectedBrandSpecCls {
    position: absolute ;
    top: -82px;
    text-align: left;
    float: left;
    left: 0%;

}