//background colors
$primary: #87c3cd;
$red: #e74c3c;
$teal: #1abc9c;
$white: #ffffff;
$yellow: #d8a93c;
$green: #35cb82;
$body: #f5f7f9;
$purple: #896ad4;
$gray-dark: #f5f5f5;
$bg-light-gray: #fbfbfb;
$menu-bg: #f2f2f2;
$modal-bg: #f3f3f3;
$control-bg: #f1f1f1;
$theme: #ff6358;
//colors
$text-primary: #3a5153;
$text-secondary: #6a6a6a;
$text-gray-normal: #b8b8b8;
$text-white: #ffffff;
$text-black: #000000;
$text-lightgray: #585858;
//borders
$border-primary: #025175;
$border-secondary: #f3be25;
$border-white: #ffffff;
$border-black: #000000;
$border-lightgray: #dfdfdf;
//btn colors
.text-red {
  color: $red;
}
.text-blue {
  color: $primary;
}
.text-dark-blue {
  color: #003e58;
}
.text-green {
  color: $green;
}
.text-black {
  color: $text-black;
}
.text-yellow {
  color: $yellow;
}
.text-white {
  color: $white;
}
