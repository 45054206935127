.fs-12 {
  font-size: 0.75rem;
}
.fs-13 {
  font-size: 0.8125rem;
}
.fs-14 {
  font-size: 0.875rem;
}
.fs-15 {
  font-size: 0.9375rem;
}
.fs-16 {
  font-size: 1rem;
}
.fs-17 {
  font-size: 1.0625rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-19 {
  font-size: 1.1875rem;
}
.fs-20 {
  font-size: 1.25rem;
}
.fs-21 {
  font-size: 1.3125rem;
}
.fs-22 {
  font-size: 1.375rem;
}
.fs-23 {
  font-size: 1.4375rem;
}
.fs-24 {
  font-size: 1.5rem;
  line-height: 1em;
}
.fs-25 {
  font-size: 1.545rem;
  line-height: 1em;
}
.fs-26 {
  font-size: 1.775rem;
  line-height: 1em;
}
.fs-27 {
  font-size: 1.8rem;
  line-height: 1em;
}
.fs-28 {
  font-size: 1.825rem;
  line-height: 1em;
}
.fs-29 {
  font-size: 1.85rem;
  line-height: 1em;
}
.fs-30 {
  font-size: 1.875rem;
  line-height: 1em;
}
